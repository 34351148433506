$(document).ready(function () {
    var x = 3;
    var y = 3;
    var b = 3;

    var path = [10, 10, 35, 10, 60, 35, 35, 60, 10, 60, 35, 35, 10, 10];

    if (jQuery('.arrow1').length > 0) {
        var svg1 = Snap('.arrow1');
        var f1 = svg1.filter(Snap.filter.shadow(x, y, b, '#000', 0.5));
        var arrow1 = svg1.polyline(path);
        arrow1.attr({strokeWidth: 0, fill: "#fff", filter: f1});

        var svg2 = Snap('.arrow2');
        var f2 = svg2.filter(Snap.filter.shadow(x, y, b, '#000', 0.5));
        var arrow2 = svg2.polyline(path);
        arrow2.attr({strokeWidth: 0, fill: "#fff", filter: f2});

        var svg3 = Snap('.arrow3');
        var f3 = svg3.filter(Snap.filter.shadow(x, y, b, '#000', 0.5));
        var arrow3 = svg3.polyline(path);
        arrow3.attr({strokeWidth: 0, fill: "#fff", filter: f3});
    }

});